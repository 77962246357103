.wrap {
  background-color: white;
  height: 800px;
}

.div {
  display: flex;
  justify-content: center;
}

.title {
  text-align: center;
  display: block;
  color: rgb(66, 66, 66);
  padding-top: 100px;
}

.img {
  width: 100px;
  height: 100px;
  display: block;
  text-align: center;
  margin: 2rem;
  transition: transform 0.5s ease-in-out;
}

@media screen and (max-width: 960px) {
  .img {
    width: 100px;
    height: 100px;
    display: block;
    text-align: center;
    margin: 0.5rem;
    margin-top: 5rem;
  }
}
