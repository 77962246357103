.div {
  text-align: center;
  margin-top: 14rem;
}

.title {
  text-align: center;
  color: rgb(66, 66, 66);
  padding-bottom: 4rem;
}

.button {
  background-color: white;
  color: rgb(66, 66, 66);
  width: 200px;
  height: 50px;
  text-align: center;
  border: none;
}

.active {
  background-color: rgb(255, 61, 94);
  color: rgb(66, 66, 66);
  width: 200px;
  height: 50px;
  text-align: center;
  border: none;
}

.button2:focus {
  background-color: rgb(255, 61, 94);
}

@media screen and (max-width: 960px) {
  .button {
    background-color: white;
    color: rgb(66, 66, 66);
    width: 30%;
    height: 50px;
    text-align: center;
    border: none;
  }

  .active {
    background-color: rgb(255, 61, 94);
    color: rgb(66, 66, 66);
    width: 30%;
    height: 50px;
    text-align: center;
    border: none;
  }
}
