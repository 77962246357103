.container {
    margin-top: 14rem;
}

.title {
    text-align: center;
    color: rgb(66, 66, 66);
}

.button {
    text-align: right;
    
}

@media screen and (max-width: 960px) {
    .button {
        text-align: center;
    }
}