.container{
  display: flex;
  justify-content: center;
}

.div {
  display: flex;
  flex-wrap: wrap;
  margin-top: 2rem;
  justify-content: center;
  text-align: center;
  max-width: 1060px;
}

@media screen and (max-width: 960px) {
  .div {
    width: 100%;
    text-align: center;
    display: inline-block;
  }
}
