.div {
  display: flex;
  justify-content: center;
}

.wrap {
  margin: 3rem;
  width: 50%;
}

.sentence {
  margin-top: 2rem;
}

.title {
  color: rgb(66, 66, 66);
  padding-top: 100px;
}

.edu {
  color: rgb(66, 66, 66);
  padding-top: 50px;
}

.uni {
  display: flex;
}

.img {
  width: 120px;
  height: 120px;
  margin: 2rem;
  transition: transform 0.5s ease-in-out;
}

.img:hover {
  transform: rotateY(180deg);
}

.sentenceEdu {
  margin: auto;
  font-size: small;
}

@media screen and (max-width: 960px) {
  .wrap {
    margin: 3rem;
    width: 90%;
  }

  .title {
    color: rgb(66, 66, 66);
    padding-top: 100px;
    text-align: center;
  }

  .edu {
    color: rgb(66, 66, 66);
    padding-top: 50px;
    text-align: center;
  }
  .uni {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
  }

  .img {
    width: 120px;
    height: 120px;
    transition: transform 0.7s ease-in-out;
    margin: auto;
  }

  .sentenceEdu {
    margin: auto;
  }
}
