.div {
  padding-top: 7rem;
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 960px) {
  .div {
    display: block;
    text-align: center;
  }
}