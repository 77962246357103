.div {
}
.first {
  text-align: center;
  display: block;
}
.second {
  margin-top: 2rem;
  text-align: center;
  display: block;
  color: rgb(66, 66, 66);
}

.image {
  width: 295px;
  height: 325px;
  border-radius: 20px;
}

.title2 {
  margin-top: 1rem;
}

.name {
  color: rgb(255, 61, 94);
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
}

.title {
  border-right: solid 3px rgba(255, 61, 94, 0.75);
  white-space: nowrap;
  overflow: hidden;
  margin: 0 auto;
}

.typical {
  color: rgb(255, 61, 94);
  font-family: Arial, Helvetica, sans-serif;
}

/* Animation */
.title {
  animation: animated-text 4s steps(29, end) 1s 1 normal both,
    animated-cursor 600ms steps(29, end) infinite;
}

/* text animation */

@keyframes animated-text {
  from {
    width: 0;
  }
  to {
    width: 320px;
  }
}

/* cursor animations */

@keyframes animated-cursor {
  from {
    border-right-color: rgba(255, 61, 94, 0.75);
  }
  to {
    border-right-color: transparent;
  }
}
