.footer {
  background-color: #050A30;
  padding-top: 3rem;
  position: relative;
  bottom: 0;
  width: 100%;
}

.name {
    text-align: center;
    color: white;
    padding: 1rem;
    font-size: 12px;
}

.date {
    color: rgb(255, 61, 94);
}