.container {
    text-align: center;
    padding: 25px 50px;
}

.linkedin {
    color:white;
    margin: 0 1rem;
    transition: transform 250ms;
    display: inline-block;
}

.linkedin:hover{
    transform:translateY(-2px)
}

.instagram {
    color: white;
    margin: 0 1rem;
    transition: transform 250ms;
    display: inline-block;
}

.instagram:hover{
    transform:translateY(-2px)
}

.github {
    color: white;
    margin: 0 1rem;
    transition: transform 250ms;
    display: inline-block;
}

.github:hover{
    transform:translateY(-2px)
}