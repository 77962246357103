.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.75);
}

.container {
  border-style: solid;
  border-color: red;
}

.modal {
  position: fixed;
  /*
  top: 15vh;
  left: 5%;
  width: 90%;
  max-height: calc(100vh - 210px);
  */
  top: 15vh;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  max-height: calc(100vh - 210px);
  width: 70%;

  background-color: white;
  padding: 1rem;
  border-radius: 14px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 30;
  animation: slide-down 300ms ease-out forwards;
  overflow-y: auto;
}

.modal::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.modal::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
.modal::-webkit-scrollbar-thumb {
  background: rgb(255, 61, 94);
  border-radius: 10px;
}

/* Handle on hover */
.modal::-webkit-scrollbar-thumb:hover {
  background: rgb(255, 61, 94);
}

@media screen and (max-width: 960px) {
  .modal {
    width: 95%;
    max-height: calc(100vh);
  }
}

@keyframes slide-down {
  from {
    opacity: 0;
    transform: translateY(-3rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
