.main {
  margin: 3rem auto;
  /** max-width: 40rem;**/
}

.pageContainer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.contentWrap {
  flex: 1;
}
