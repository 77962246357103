.div {
    display: grid;
    grid-template-columns: 85% 15%;
  background-color: #67ffa5;
}

.p {
  color: rgb(66, 66, 66);
  margin: 0.5rem;
  text-align: center;
}

.a {
  color: rgb(66, 66, 66);
  margin: 0.5rem;
}
